import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import Projects from '../components/projects'

import SeoSearch from '../components/seo-search'

import FeaturedImage from '../components/featured-image'

import { TiInfoLarge } from 'react-icons/ti'

import { DataCard } from '../components/card'

export default ({ data }) => {
    const developer = data.allWordpressWpDevelopers.edges[0].node

    return (
        <Layout
            marketTicker
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Developers',
                    link: '/developers'
                },
                {
                    title: developer.name
                }
            ]}
        >
            <SEO
                title={developer.acf && developer.acf.meta_title}
                description={developer.acf && developer.acf.meta_description}
            />
            <FeaturedImage
                alt={
                    developer.acf &&
                    developer.acf.developer_image &&
                    developer.acf.developer_image.alt_text
                }
                fluid={
                    developer.acf &&
                    developer.acf.developer_image &&
                    developer.acf.developer_image.localFile &&
                    developer.acf.developer_image.localFile.childImageSharp
                        .fluid
                }
                title={developer.name}
            />
            {data.allWordpressWpProject && <Projects data={data} />}
            <div className="pageContainer">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            <DataCard
                                label={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: 'About ' + developer.name
                                        }}
                                    />
                                }
                                icon={<TiInfoLarge />}
                            >
                                {developer.acf.content ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: developer.acf.content
                                        }}
                                    />
                                ) : (
                                    'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                                )}
                            </DataCard>
                        </div>
                    </div>
                </div>
            </div>

            <SeoSearch data={data} />
        </Layout>
    )
}
export const query = graphql`
    query($slug: String!, $id: [Int!]) {
        allWordpressWpDevelopers(filter: { slug: { eq: $slug } }) {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                    acf {
                        content
                        meta_title
                        meta_description
                        developer_image {
                            alt_text
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 900) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        allWordpressWpAreas {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }

        allWordpressWpProject(filter: { developers: { in: $id } }) {
            edges {
                node {
                    title
                    developers
                    areas
                    slug
                    acf {
                        investor_grade
                        end_user_grade
                    }
                    featured_media {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }

        allWordpressWpSeoSearch {
            edges {
                node {
                    title
                    slug
                    wordpress_id
                    seo_search_category
                    areas
                }
            }
        }

        allWordpressWpSeoSearchCategory {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }
    }
`
